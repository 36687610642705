import React, { useState, useEffect } from 'react';
import NextNavLink from '../../util/NextNavLink';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Navigation]);
import ReactPlayer from 'react-player';
import Fancybox from './fancybox';
import StorySlide from './slide';
import Dropdown from './dropdown';

function groupArray(array, groupSize) {
    const groups = [];
    const finalArray = array;
    // const finalArray = [
    //     ...array,
    //     ...array,
    //     ...array,
    //     ...array,
    //     ...array,
    //     ...array,
    //     ...array,
    //     ...array
    // ];
    for (let i = 0; i < finalArray.length; i += groupSize) {
        groups.push(finalArray.slice(i, i + groupSize));
    }
    return groups;
}

export const SwiperHandler = ({ groupSlides, category }) => {
    const [currentSlide, setCurrentSlide] = useState(1);

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
        }
    };
    const noPagination = {
        renderBullet: function (index, className) {
            return ``;
        }
    };

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            className="client-carousel-bullets client-stories-carousel"
            pagination={
                groupSlides(category).length <= 1 ? noPagination : pagination
            }
            allowTouchMove={groupSlides(category).length <= 1 ? false : true}
            // onSlideChange={(swiper) =>
            //     console.log(
            //         'slide change working',
            //         setCurrentSlide(swiper.realIndex)
            //     )
            // }
        >
            {groupSlides(category).map((items, slideIndex) => (
                <SwiperSlide key={slideIndex}>
                    <div className="flex flex-wrap flex-col md:flex-row gap-[35px] w-full">
                        {items.map((stories, idx) => (
                            <StorySlide item={stories} index={idx} />
                        ))}
                    </div>
                </SwiperSlide>
            ))}
            {groupSlides(category).length <= 1 ? null : (
                <p className="swiper-tracker swiper-tracker-position right-6">{`${
                    currentSlide + 1
                } / ${groupSlides(category).length}`}</p>
            )}
        </Swiper>
    );
};

export const ClientStoriesCarousel = ({
    clientStory,
    heading,
    hideFilters,
    className = '',
    anchor = '',
    groups
}) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    // const [currentSlide, setCurrentSlide] = useState(1);

    const clearSelection = () => {
        setSelectedCategory('');
    };

    const groupSlides = (category) => {
        const matchingStories = clientStory.filter((post) => {
            if (post.category === category) {
                if (selectedCategory)
                    return post.subcategories.includes(selectedCategory);
                return true;
            }
            return false;
        });
        const groups = groupArray(matchingStories, 6);
        return groups;
    };
    return (
        <div
            className={`relative wow fadeInUp overflow-hidden ${className} `}
            id={anchor}
        >
            {hideFilters == 0 ? 
                (<div className="bg-gray-stories shadow-stories">
                    <div className="container flex flex-row  py-[18px]">
                        <Dropdown
                            options={groups.subcategories}
                            selected={selectedCategory}
                            onChange={(value) => {
                                if (value === 'all') {
                                    return setSelectedCategory(null);
                                }
                                return setSelectedCategory(value);
                            }}
                        />

                        <button
                            onClick={clearSelection}
                            className="ml-8 text-18 font-normal text-purple"
                        >
                            Clear All
                        </button>
                    </div>
                </div>) : null}
            {Array.isArray(groups.cat) &&
                groups.cat.map((category, groupIndex) => {
                    if (groupSlides(category).length > 0) {
                        return (
                            <div
                                className="container relative mt-10 mb-16"
                                key={groupIndex}
                            >
                                <h2 className="text-2xl mb-4 md:ml-4 flex w-1/2 carouselheading">
                                    {category}
                                </h2>
                                <SwiperHandler
                                    key={groupIndex}
                                    groupSlides={groupSlides}
                                    category={category}
                                />
                            </div>
                        );
                    } else return null;
                })}
        </div>
    );
};

export default ClientStoriesCarousel;