import React from 'react';
import { SimpleCTA } from '../../components/enfusion/SimpleCTA';

function ACFSimpleCTA({ data, className, anchor }) {
    const { title, content, cta } = data;

    return (
        <SimpleCTA
            className={className}
            anchor={anchor}
            title={title}
            content={content}
            cta={cta}
        />
    );
}

export default ACFSimpleCTA;
