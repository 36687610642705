import React from 'react';

export const SimpleCTA = ({ title, content, cta, className, anchor }) => {
    return (
        <div
            id={anchor}
            className={`${className} ${!!cta.url ? 'bg-gray-19' : ''}`}
        >
            <div className="container py-[62px]">
                {title ? (
                    <h2 className="text-center font-semibold text-20 md:text-24 leading-32 py-[15px]">
                        {title}
                    </h2>
                ) : (
                    <></>
                )}

                <div className="text-center font-normal text-20 md:text-24 w-5/6 mx-auto leading-[35px] py-[15px]">
                    {content}
                </div>
                {!!cta?.url && (
                    <div className="text-center pt-[15px]">
                        <a
                            href={cta.url}
                            className="cursor-pointer btn btn--primary"
                        >
                            {cta.title}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SimpleCTA;
