import React from 'react';

import { HalfHeightHero } from '../../components/enfusion/HalfHeightHero';

function ACFHalfHeightHero({ meta, data, className, anchor, pageProps, bid }) {
    const { themeAssets } = pageProps;

    const {
        desktop_image,
        description,
        text_area,
        main_heading_1,
        main_heading_2,
        top_heading,
        style_options_background_image,
        style_options_background_pattern,
        mobile_image,
        style_options_dark,
        style_options_wide_image,
        style_options_top_heading_icon = [],
        cta = [],
        cta2 = [],
        style_options_scroll_anchor,
        style_options_image_is_background,
        style_options_cta_on_right,
        style_options_scroll_arrow,
        pardot_link
    } = data;
    let bg = meta?.images[desktop_image];
    let mobileBg = meta?.images[mobile_image];
    let background_pattern = themeAssets[style_options_background_pattern];
    let background_image = themeAssets[style_options_background_image];
    let headingIcon = themeAssets[style_options_top_heading_icon[0]];

    return (
        <HalfHeightHero
            className={className}
            anchor={anchor}
            bg={bg}
            mobileBg={mobileBg}
            top_heading={top_heading}
            main_heading_1={main_heading_1}
            main_heading_2={main_heading_2}
            description={description}
            text_area={text_area}
            background_image={background_image}
            background_pattern={background_pattern}
            style_dark_light={style_options_dark}
            headingIcon={headingIcon}
            cta={cta}
            cta2={cta2}
            wide={style_options_wide_image}
            scrollto={style_options_scroll_anchor}
            image_is_background={style_options_image_is_background}
            cta_on_right={style_options_cta_on_right}
            scroll_arrow={style_options_scroll_arrow}
            pardot_link={pardot_link}
            bid={bid}
        />
    );
}

export default ACFHalfHeightHero;
