import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';

export const IconContentList = ({
    className = '',
    anchor = '',
    lists,
    cta
}) => {
    return (
        <div className={`md:pb-32 pb-16 overflow-hidden ${className}`} id={anchor}>
            <div className="container">
                <div>
                {lists &&
                    lists.map((item, i) => (
                        <div key={i} data-wow-delay={`${i / 7}s`} className="mb-12 last:mb-0 wow fadeInUp md:pb-8 md:mb-8 md:border-b md:grid md:grid-cols-12 gap-14 border-opacity-20 border-purple-midnight first:md:border-t first:pt-8 lg:pl-2">
                            <div className="col-span-2 mb-5 text-center md:border-r border-opacity-20 border-purple-midnight md:mb-0">
                                <div className="flex items-center h-full md:justify-center">
                                    {item?.image_icon && 
                                        <i className={`${item?.image_icon?.class} text-40 text-purple `} />
                                    }
                                </div>
                            </div>
                            <div className="col-span-10">
                                <div className="grid-cols-12 md:grid">
                                    <div className="col-span-6 mb-4 md:pr-16 md:mb-0">
                                        <div className="flex items-center h-full">
                                            {item.heading && (
                                                <h5
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.heading
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-span-6 md:border-l border-opacity-20 border-purple-midnight md:pl-14 ">
                                        {item.text_area && (
                                            <div
                                                className="lead opacity-70"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.text_area
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    ))}
                </div>
                <div className="flex flex-col mt-6 md:space-x-10 md:mt-16 md:flex-row">
                    {cta.map((cta, index) =>
                        <NextNavLink
                            target={cta.link.target}
                            href={cta.link.url} 
                            key={index}>
                            <a
                                    className="inline-flex items-center mt-10 font-medium md:mt-0 text-purple-midnight lead icon-circle "
                                >
                                    {cta?.link.title}
                                    <span className="relative flex items-center h-4 ml-4">
                                        <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                        <svg
                                            width="32"
                                            height="32"
                                            className="absolute -right-3 -top-2 icon-circle__circle"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__base"
                                            ></circle>
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15"
                                                className="icon-circle__fill icon-circle__fill--dark"
                                            ></circle>
                                        </svg>
                                    </span>
                            </a>
                        </NextNavLink>
                
                    )}
                </div>
            </div>
        </div>
    );
};
export default IconContentList;
