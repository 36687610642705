import React, { useState } from 'react';

import { Transition } from '@headlessui/react';

export const TwoColAccordianList = ({
    className = '',
    anchor = '',
    lists,
    main_heading,
    top_heading,
    description
}) => {
    const [disclosureState, setDisclosureState] = useState(false);

    const toggle = (index) => {
        if (disclosureState === index) {
            return setDisclosureState(null);
        }
        setDisclosureState(index);
    };
    return (
        <div
            className={`py-16  overflow-hidden relative wow fadeInUp ${className}`}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div className="grid gap-12 lg:gap-4 lg:grid-cols-2">
                    <div className="md:pr-28">
                        {top_heading && (
                            <p
                                className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: top_heading
                                }}
                            />
                        )}
                        {main_heading && (
                            <h3
                                className="mb-4 md:mb-6 h2 md:h3 wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: main_heading
                                }}
                            />
                        )}
                        {description && (
                            <div
                                className="lead lead--large opacity-70 wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: description
                                }}
                            />
                        )}
                    </div>
                    <div className="md:pl-2">
                        <div>
                            {lists &&
                                lists.map((item, index) => (
                                    <div
                                        className="border-purple-midnight last:border-b border-opacity-10 wow fadeInUp"
                                        key={index}
                                    >
                                        <div
                                            className="flex items-center py-6 border-t cursor-pointer border-purple-midnight lead md:lead--large border-opacity-10 "
                                            onClick={() => {
                                                toggle('col1' + index);
                                            }}
                                        >
                                            <i className="mr-6 lg:mr-8 en-circle-check text-24 text-purple"></i>
                                            <span
                                                className="flex flex-1 lead lead--large"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.main_heading
                                                }}
                                            />
                                            <i
                                                className={`en-caret-down opacity-30 inline-block transform text-14 ${
                                                    disclosureState ===
                                                    'col1' + index
                                                        ? 'rotate-180'
                                                        : ''
                                                }`}
                                            ></i>
                                        </div>
                                        <Transition
                                            show={
                                                disclosureState ===
                                                'col1' + index
                                            }
                                            className="pb-6 pl-12 lg:pl-14"
                                        >
                                            <div
                                                className=" lead opacity-70"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.description
                                                }}
                                            />
                                        </Transition>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TwoColAccordianList;
