import React, { useState } from 'react';
import { getAbsoluteUrl } from '../../../lib/helpers';

export const TwoColTextImage = ({
    className = '',
    anchor = '',
    headerText,
    secondHeader,
    content,
    desktopImage,
    mobileImage,
    flip,
    wide,
    button,
    cta
}) => {
    const noPadding = className.search('no-padding-on-text');

    return (
        <div
            className={`lg:py-24 py-16 overflow-hidden ${className}`}
            id={anchor}
        >
            <div className={`${wide && flip ? '' : 'container'} relative`}>
                <div
                    className={`${
                        flip
                            ? 'lg:flex-row-reverse flex-col-reverse'
                            : 'lg:flex-row flex-col'
                    } items-center flex`}
                >
                    <div
                        className={`lg:w-1/2  ${
                            flip
                                ? `${
                                      noPadding === -1
                                          ? `lg:pl-28 lg:pr-0 px-4`
                                          : ''
                                  } mt-16 lg:mt-0`
                                : 'lg:pr-28 mb-16 lg:mb-0'
                        }`}
                    >
                        <div
                            className={` ${
                                wide && flip ? 'max-w-[560px]' : ''
                            }`}
                        >
                            {headerText && (
                                <h5
                                    className="mb-4 font-medium lg:mb-6 label text-purple wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: headerText
                                    }}
                                />
                            )}
                            {secondHeader && (
                                <h3
                                    className="mb-4 lg:mb-6 text-purple-midnight h2 md:h3 wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: secondHeader
                                    }}
                                />
                            )}
                            {content && (
                                <div className="wow fadeInUp">
                                    <div
                                        className="lead lead--large text-purple-midnight opacity-70"
                                        dangerouslySetInnerHTML={{
                                            __html: content
                                        }}
                                    />
                                </div>
                            )}
                            {button && cta && (
                                <div className="mt-8 lg:mt-12 wow fadeInUp">
                                    <a
                                        href={getAbsoluteUrl(cta.url)}
                                        className="cursor-pointer btn btn--primary"
                                        target={cta.target}
                                    >
                                        {cta.title}
                                    </a>
                                </div>
                            )}
                            {!button && cta && (
                                <div className="mt-8 lg:mt-12 wow fadeInUp">
                                    <a
                                        href={getAbsoluteUrl(cta.url)}
                                        className="cursor-pointer link link--primary"
                                        target={cta.target}
                                    >
                                        {cta.title}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="lg:w-1/2 wow fadeInRight">
                        {wide && (
                            <>
                                {desktopImage?.src && (
                                    <div
                                        className={`relative w-screen transform lg:max-w-[50vw] top-0 lg:-left-2  ${
                                            flip
                                                ? '-left-7 md:left-auto'
                                                : '-right-7 md:right-auto'
                                        } ${
                                            mobileImage?.src
                                                ? 'md:block hidden'
                                                : ''
                                        }`}
                                    >
                                        <img
                                            src={desktopImage?.src}
                                            alt={
                                                desktopImage?.title ||
                                                'enfusion'
                                            }
                                            className="w-full"
                                        />
                                    </div>
                                )}
                                {mobileImage?.src && (
                                    <div
                                        className={`relative transform lg:max-w-[50vw] lg:-left-2 lg:pl-0 -right-7 top-0 ${
                                            mobileImage?.src ? 'md:hidden' : ''
                                        }`}
                                    >
                                        <img
                                            src={mobileImage?.src}
                                            alt={
                                                mobileImage?.title || 'enfusion'
                                            }
                                            className="w-full"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {!wide && (
                            <>
                                {desktopImage?.src && (
                                    <div
                                        className={`relative text-center ${
                                            mobileImage?.src
                                                ? 'md:block hidden'
                                                : ''
                                        }`}
                                    >
                                        <img
                                            src={desktopImage?.src}
                                            alt={
                                                desktopImage?.title ||
                                                'enfusion'
                                            }
                                            className={`w-full max-w-max mx-auto`}
                                        />
                                    </div>
                                )}
                                {mobileImage?.src && (
                                    <div
                                        className={`relative  ${
                                            mobileImage?.src ? 'md:hidden' : ''
                                        }`}
                                    >
                                        <img
                                            src={mobileImage?.src}
                                            alt={
                                                mobileImage?.title || 'enfusion'
                                            }
                                            className={`w-full `}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TwoColTextImage;
