import NextNavLink from '@cutil/NextNavLink';
import Fancybox from './fancybox';

export default function StorySlide({ item }) {
    return (
        <div className="client-swiper-slide">
            <div className="card">
                <div className="card-image">
                    {!!item?.featuredImage?.featuredImageSrc ? (
                        <img
                            src={item?.featuredImage.featuredImageSrc}
                            alt=""
                        />
                    ) : (
                        <img src="/images/enfusion-placeholder.png" alt="" />
                    )}
                    {!!item.featuredVideo && (
                        <Fancybox>
                            <div className="w-full absolute top-[59px]">
                                <img
                                    className="mx-auto cursor-pointer"
                                    src="/images/play-alternate.svg"
                                    style={{
                                        position: 'static',
                                        height: '59px',
                                        width: '59px'
                                    }}
                                ></img>
                            </div>
                            <a
                                className="absolute w-full h-full flex items-end justify-start top-0 left-0 cursor-pointer text-white"
                                data-fancybox="video-gallery"
                                rel="gallery01"
                                href={item.featuredVideo}
                            ></a>
                        </Fancybox>
                    )}
                </div>
                <NextNavLink href={item.permalink} className="cursor-pointer">
                    <a>
                        <div className="card-body">
                            <div className="card-body-content text-black hover:text-purple">
                                {Array.isArray(item.subcategories) && (
                                    <p className="mb-4 text-12 font-medium leading-18 tracking-250 uppercase">
                                        {item.subcategories
                                            .slice(0, 2)
                                            .join('/')}
                                    </p>
                                )}

                                {item?.logoImage.logoImageSrc && (
                                    <div className="h-[60px]">
                                        <img
                                            src={item?.logoImage.logoImageSrc}
                                            alt=""
                                            className=""
                                        />
                                    </div>
                                )}
                                <p
                                    className="mb-4 pt-8 h-[130px]"
                                    dangerouslySetInnerHTML={{
                                        __html: item.title
                                    }}
                                />
                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1] text-purple"></i>
                            </div>
                        </div>
                    </a>
                </NextNavLink>
            </div>
        </div>
    );
}
