import React from 'react';

export const InvestmentStrategies = ({ className, anchor, heading, data }) => {
    const strategies = Array.from(Array(data.strategies), Math.random);
    return (
        <div className={`${className} py-24 container`} id={anchor}>
            {heading ? (
                <span className="block text-center text-32 max-w-[52ch] mx-auto">
                    {heading}
                </span>
            ) : null}
            {strategies ? (
                <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-6 gap-4 md:gap-[90px] mt-[57px] container mx-auto">
                    {strategies.map(
                        (strategy, i) =>
                            data[`strategies_${i}_abbreviation`] &&
                            data[`strategies_${i}_name`] && (
                                <a
                                    href={data[`strategies_${i}_link`]}
                                    className="text-purple-midnight"
                                >
                                    <div>
                                        <div
                                            className="p-5 border-transparent rounded-[100%] w-[102px] h-[102px] mb-[23px] mx-auto flex items-center justify-center"
                                            style={{
                                                border: '4px solid transparent',
                                                background:
                                                    'linear-gradient(#fff, #fff) padding-box, linear-gradient(248.09deg, #4A25AA, #7D5FFF) border-box'
                                            }}
                                        >
                                            <p className="text-center font-semibold text-[38px] leading-32">
                                                {
                                                    data[
                                                        `strategies_${i}_abbreviation`
                                                    ]
                                                }
                                            </p>
                                        </div>
                                        <p className="text-18 md:text-[24px] leading-[30px] text-center">
                                            {data[`strategies_${i}_name`]}
                                        </p>
                                    </div>
                                </a>
                            )
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default InvestmentStrategies;