import React, { useState, useEffect } from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowDimensions } from '../../../util/hooks';
SwiperCore.use([Pagination, Navigation, Autoplay]);

export const TwoColPartnersNumbers = ({
    className = '',
    anchor = '',
    partnersTitle,
    partnersLogos,
    numbersTitle,
    numbersGrid,
    numbersTitleGrid
}) => {
    const { width } = useWindowDimensions();
    const [slides, setSlides] = useState(
        chunkIntoN(partnersLogos, width <= 767 ? 6 : 12)
    );
    const [currentSlide, setCurrentSlide] = useState(1);
    const [disableSwiper, setDisableSwiper] = useState(
        partnersLogos.length <= (width <= 767 ? 6 : 12)
    );

    useEffect(() => {
        function handleResize() {
            const newWidth = window.innerWidth;
            const newSlides = chunkIntoN(
                partnersLogos,
                newWidth <= 767 ? 6 : 12
            );
            const newDisableSwiper =
                partnersLogos.length <= (newWidth <= 767 ? 6 : 12);
            setSlides(newSlides);
            setDisableSwiper(newDisableSwiper);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [partnersLogos]);

    function SlideGroup({ slide, id }) {
        return (
            <div className="two-col-partners-numbers__slide">
                {slide.map((img, index) => (
                    <figure>
                        <img
                            key={id + '' + index}
                            src={img}
                            alt=""
                            className="two-col-partners-numbers__logo"
                        />
                    </figure>
                ))}
            </div>
        );
    }

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        }
    };

    return (
        <div className={`two-col-partners-numbers ${className}`} id={anchor}>
            <div className="two-col-partners-numbers__wrapper">
                <div className="two-col-partners-numbers__inner">
                    <div className="two-col-partners-numbers__left">
                        <h2 className="two-col-partners-numbers__title">
                            {partnersTitle}
                        </h2>
                        <div className="two-col-partners-numbers__logos">
                            {disableSwiper ? (
                                slides.map((slide, index) => (
                                    <SlideGroup slide={slide} id={index} />
                                ))
                            ) : (
                                <Swiper
                                    onSlideChange={(ev) =>
                                        setCurrentSlide(ev.realIndex + 1)
                                    }
                                    autoplay={true}
                                    loop={true}
                                    pagination={
                                        disableSwiper ? false : pagination
                                    }
                                >
                                    {slides.map((slide, index) => (
                                        <SwiperSlide key={index}>
                                            <SlideGroup
                                                slide={slide}
                                                id={index}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )}
                            {!disableSwiper && (
                                <p className=" absolute right-4 bottom-2 text-14 tracking-25 text-black">
                                    <span className=" tabular-nums">
                                        {currentSlide}
                                    </span>
                                    {'   '}/ {slides.length}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="two-col-partners-numbers__right">
                        <div className="two-col-partners-numbers__eyebrow">
                            Statistics
                        </div>
                        <h2 className="two-col-partners-numbers__title">
                            {numbersTitleGrid}
                        </h2>
                        <div className="two-col-partners-numbers__grid">
                            {numbersGrid.map(({ number, title }, index) => (
                                <div
                                    key={index}
                                    className="two-col-partners-numbers__grid-item"
                                >
                                    <div className="two-col-partners-numbers__grid-item-number">
                                        {number}
                                    </div>
                                    <div className="two-col-partners-numbers__grid-item-title">
                                        {title}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const chunkIntoN = (arr, n) => {
    const size = Math.ceil(arr.length / n);
    return Array.from({ length: size }, (v, i) => arr.slice(i * n, i * n + n));
};
export default TwoColPartnersNumbers;
