import React, { useState, useEffect } from 'react';
import NextNavLink from '../../util/NextNavLink';
import SwiperCore, { Pagination, Navigation,  Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const TestimonialCarousel = ({
  className = '',
  anchor = '',
  pattern,
  heading,
  testimonials,
  size = 'lg'
}) => {
  let swiperProps = {
    loop: true
  }
  if (size === 'lg') {
    swiperProps = {
      ...swiperProps,
      loop: false,
      navigation: {
        prevEl: '.testimonials-prev',
        nextEl: '.testimonials-next'
      },
      pagination:{
        clickable: true,
        el: `.testimonials-custom-pagination`
      },
      modules:[Pagination, Navigation]
    }
  } else {
    swiperProps.autoplay = {
      delay: 7000,
      disableOnInteraction: false
    }
    swiperProps.modules = [Autoplay]
  }
  return (
    <div
      className={`testimonials testimonials--${size}  wow fadeInUp ${className} `}
      id={anchor}
    >
      <div className="container relative z-[1] ">
        <Swiper
          key={`carrousel-${testimonials.length}`}
          spaceBetween={0}
          slidesPerView={1}
          {...swiperProps}
        >
          {testimonials &&
            testimonials.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`slide`}
                >
                  <div className={`slide--left `}>
                    <img src={size === 'lg'? "/images/quote.svg": "/images/quote-dark.svg"} className="md:w-full w-[110px] mx-auto block" alt="" />
                  </div>
                    <div className="slide--right">
                      <div className="flex items-center h-full">
                        <div>
                          {heading ? (
                            <p className="slide--subtitle">{heading}</p> 
                          ) : (
                            <p className="slide--subtitle">WHAT OUR CLIENTS SAY</p>
                          )
                          }
                                                                          
                          {item?.content && (
                            <h3
                              className="slide--testimonial"
                              dangerouslySetInnerHTML={{
                                __html: item?.content
                              }}
                            />
                          )}
                          <div
                            className="slide--tname"
                            dangerouslySetInnerHTML={{
                              __html: item?.title
                            }}
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        {size === 'lg' && 
          <div className="tpagination">
            <div className="tpagination--left"></div>
            <div className="tpagination--right">
              <div className="flex items-center justify-between gap-8 mt-10 md:inline-flex">
                <div className="cursor-pointer testimonials-prev">
                  <i className="inline-block transform rotate-180 en-long-arrow text-32 text-purple-midnight"></i>
                </div>
                <div className="testimonials-custom-pagination"></div>
                <div className="cursor-pointer testimonials-next">
                  <i className="en-long-arrow text-32 text-purple-midnight"></i>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className={ `lg:block hidden absolute w-[40vw] -right-32 top-1/3 h-full bg-no-repeat bg-center transform -translate-y-1/2 max-h-[290px] bg-cover  ${pattern?.class}`}></div>
    </div>
  );
};
export default TestimonialCarousel;