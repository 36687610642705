import Image from 'next/image';
export const TestimonialCard = ({testimonial, className}) => {

  const title = testimonial.title;
  const splitTitle = title.split(',');
  const name = splitTitle[0].trim(); // gets the first part
  const jobtitle = splitTitle.slice(1).join(',').trim(); // gets the last part

  return (
    <div className={`testimonial-card ${className}`}>
      <div className="rounded-10 bg-white bg-opacity-60 py-5 px-8 shadow text-20 md:flex">
        { testimonial?.image?.src && 
          <div style={{ borderRadius: '50%', overflow: 'hidden', width: '100px', height: '100px' }} className='flex-shrink-0 mb-5 md:mb-0 md:mr-8 mt-1'>
            <Image
              alt={testimonial?.image?.altText}
              src={testimonial?.image?.src}
              srcSet={testimonial?.image?.srcSet} 
              width={100}
              height={100}
            />
          </div>
        }
        <div>
          <div className="testimonial-card__quote" dangerouslySetInnerHTML={{ __html: testimonial?.content, }} />
          <p className="mt-5">
            {name}
            <b className="text-purple text-12 block" dangerouslySetInnerHTML={{ __html: jobtitle, }} />
          </p>
        </div>
      </div>
    </div>
  );

};
export default TestimonialCard;
