import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';
import PardotLink from '../PardotPopup';

export const IconContentGrid = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    left_content,
    content,
    background = '',
    light_dark,
    lists,
    defaultData
}) => {
    const [ modalState, setModalState] = useState(false)
    const [mItem, setMItem] = useState({})
    
    const validPardot = ['np', 'fs']
    const handlePardotOpen = (item) => (ev) => {
        ev.preventDefault()
        setMItem({...item, cta: item.link})
        setModalState(true)
    }

    return (
        <>
        <div
            className={`md:pt-32 md:pb-24 py-16 overflow-hidden bg-cover  wow fadeInUp ${light_dark === 'light' ? 'text-white' : 'text-purple-midnight'} ${background} ${className}`}
            id={anchor}
        >
           
            <div className="container">
                <div className="grid-cols-12 gap-8 mb-12 sm:mb-16 lg:grid">
                    <div className="col-span-5 wow fadeInUp">
                        {top_heading && (<p className={ `sm:mb-6 mb-4 label font-medium ${light_dark === 'light' ? 'text-purple-periwinkle' : 'text-purple'}`} dangerouslySetInnerHTML={{__html:top_heading}} />)}
                        {main_heading && (<h3 className="" dangerouslySetInnerHTML={{__html:main_heading}} />)} 
                        {left_content && (<div className="lg:max-w-md lead lead--large lg:opacity-100 opacity-70 mt-4" dangerouslySetInnerHTML={{__html:left_content}} />)}
                    </div>
                    <div className="col-span-7 wow fadeInUp flex items-center justify-center">
                        <div className="justify-center mt-4 lg:pt-2 lg:mt-16 lg:flex">
                            {content && (<div className="lg:max-w-md lead lead--large lg:opacity-100 opacity-70" dangerouslySetInnerHTML={{ __html: content }} />)}
                        </div>
                    </div>
                </div>
                <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-7">
                    {lists && lists.map((item, i)=> 
                        <div  data-wow-delay={`${i / 7}s`} key={i} className="mb-8 last:mb-0 wow fadeInUp">
                            {item.icon?.class && 
                                <i className={`${item.icon?.class} ${light_dark === 'light' ? 'text-purple-periwinkle' : 'text-purple'} text-40  mb-4 inline-block`}></i>
                            }
                            {item.icon[0]?.acf_post_type_theme_assets?.class && 
                                <i className={`${item.icon[0]?.acf_post_type_theme_assets?.class} ${light_dark === 'light' ? 'text-purple-periwinkle' : 'text-purple'} text-40  mb-4 inline-block`}></i>
                            }
                           
                            {item.heading && 
                                <h5 className="mb-4" dangerouslySetInnerHTML={{ __html: item.heading}} />
                            }
                            {item.content && (<div className={ `mb-3 lead opacity-70`} dangerouslySetInnerHTML={{ __html: item.content }} />)}
                            {
                                validPardot.includes(item.pardot_link) && item?.link?.url ? <>
                                <a 
                                    key={`cta-link-${i}-${item.link.url}`}
                                    onClick={handlePardotOpen(item)}
                                    className="inline-flex items-center lead text-purple hover:text-purple-dark cursor-pointer"
                                >
                                    {item.link?.title}  
                                    <i className={`en-long-arrow text-32 ml-2`}></i>
                                </a>
                            </> : <>
                                {item.link && (
                                    <NextNavLink href={item.link?.url} target={item.link?.target}>
                                        <a className="inline-flex items-center lead text-purple hover:text-purple-dark" >
                                            {item.link?.title}  
                                            <i className={`en-long-arrow text-32 ml-2`}></i>
                                        </a>
                                    </NextNavLink>
                                )}
                            </> }
                        </div>
                    )}
                </div>
            </div>
        </div>
            {/* <div className="my-32">
                <div className="container grid grid-cols-2 gap-24">
                    <div></div>
                    <div className="relative overflow-hidden">
                        <div className="rev-img-wrap relative z-[1]">
                            <div className="rev-img">
                                <img src="https://brevanhoward.com/static/ph-mtd-03c91fd4fe48c26db16a3c8d32ef43b0.jpg" className="w-full" />
                            </div>
                        </div>
                        <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                    </div>
                </div>
            </div> */}
            <PardotLink 
                item={mItem}
                modalState={modalState}
                setModalState={setModalState}
            />
        </>
    );
};
export default IconContentGrid;