import React, { useState, useEffect } from 'react';
import NextNavLink from '../../util/NextNavLink';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Pagination, Navigation, Autoplay]);

import Fancybox from '../ClientStoriesCarousel/fancybox';
import ReactPlayer from 'react-player';

export const FeaturedClientStory = ({
    heading,
    clientStories,
    className = '',
    anchor = ''
}) => {
    const [currentSlide, setCurrentSlide] = useState(1);

    const pagination = {
        clickable: true,
        el: '.featured-pagination',
        renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
        }
    };
    const noPagination = {
        renderBullet: function (index, className) {
            return ``;
        }
    };

    return clientStories?.length > 0 ? (
        <div
            className={`relative wow fadeInUp overflow-hidden pt-12 pb-12 lg:pt-20 lg:pb-20 ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <h2 className="text-2xl mb-7 flex w-1/2 text-purple clientheading uppercase">
                    {heading}
                </h2>
                <Swiper
                    spaceBetween={0}
                    slidesPerView="auto"
                    autoplay={clientStories?.length <= 1 ? false : true}
                    allowTouchMove={clientStories?.length <= 1 ? false : true}
                    loop={true}
                    pagination={
                        clientStories?.length <= 1 ? noPagination : pagination
                    }
                    className="client-carousel-bullets"
                    onSlideChange={(swiper) =>
                        setCurrentSlide(swiper.realIndex + 1)
                    }
                >
                    {clientStories &&
                        clientStories.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="flex md:flex-row flex-col ">
                                    <div className="md:w-1/2 w-full flex justify-start ml-[2px]">
                                        {item.featuredVideo && (
                                            <ReactPlayer
                                                playIcon={
                                                    <img
                                                        className="mx-auto cursor-pointer"
                                                        src="/images/play-alternate.svg"
                                                        style={{
                                                            position: 'static',
                                                            height: '59px',
                                                            width: '59px'
                                                        }}
                                                    ></img>
                                                }
                                                light={
                                                    item?.featuredImage
                                                        ?.featuredImageSrc
                                                }
                                                width="640px"
                                                height="320px"
                                                className="featured-video"
                                                autoplay={false}
                                                playing={false}
                                                playsinline={true}
                                                loop={true}
                                                muted={true}
                                                controls={true}
                                                url={item.featuredVideo}
                                                config={{
                                                    vimeo: {
                                                        playerOptions: {
                                                            playsinline: 1,
                                                            muted: true
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                        {!item.featuredVideo &&
                                        item.featuredImage.featuredImageSrc ? (
                                            <div className="relative w-full md:w-[640px] md:h-[360px] h-[300px]">
                                                <img
                                                    className="absolute w-full h-full inset-0 object-cover"
                                                    src={
                                                        item?.featuredImage
                                                            ?.featuredImageSrc
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        ) : !item.featuredVideo &&
                                          !item.featuredImage
                                              .featuredImageSrc ? (
                                            <img
                                                src="/images/enfusion-placeholder.png"
                                                alt="Enfusion"
                                            />
                                        ) : null}
                                    </div>

                                    <div className="md:w-1/2 w-full md:pl-16 2xl:pl-0 px-0">
                                        <NextNavLink href={item.permalink}>
                                            <a className="text-black hover:text-purple h-full flex">
                                                <div className="lg:pr-20 pr-0 md:pt-0 pt-8 flex flex-col justify-between">
                                                    <div>
                                                        {item?.logoImage
                                                            .logoImageSrc && (
                                                            <img
                                                                style={{
                                                                    maxHeight:
                                                                        '53px',
                                                                    width:
                                                                        'auto'
                                                                }}
                                                                src={
                                                                    item
                                                                        ?.logoImage
                                                                        .logoImageSrc
                                                                }
                                                                alt=""
                                                            />
                                                        )}
                                                        {item?.clientName && (
                                                            <div
                                                                className="clientname"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        item?.clientName
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="mt-auto">
                                                        <div
                                                            className="clientexcerpt"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    item?.clientExcerpt
                                                            }}
                                                        />
                                                        <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1] text-purple mt-[25px]"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </NextNavLink>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div>
                    {clientStories?.length <= 1 ? null : (
                        <div className="hidden md:block md:pt-8">
                            <p className="swiper-tracker bottom-[-6px] 2xl:right-[784px] xl:right-[575px] lg:right-[510px] md:right-1/2 z-10">
                                {' '}
                                {currentSlide} / {clientStories?.length}
                            </p>
                        </div>
                    )}
                    <div className="featured-pagination"></div>{' '}
                </div>
            </div>
        </div>
    ) : null;
};

export default FeaturedClientStory;
