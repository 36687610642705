import React from "react";


export const ClientQuote = ({
    className,
    anchor,
    client_image,
    client_name,
    client_title,
    business_name,
    quote,
    region
}) => {

return (
    <section className="bg-white">
        <div className="container py-[62px]">
            <div className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 opacity-20 lg:left-36" />
            <figure className="flex flex-row">
                {!client_image.id ? null : (
                    <div className="flex flex-col w-1/4 items-baseline mt-6">
                        <div className="">
                            <img className="" src={client_image?.src} alt="" />
                        </div>
                        <figcaption className="mt-4 w-3/4">
                            <div className="font-semibold text-purple text-[14px] leading-[22px] mb-4">
                                {client_name}
                            </div>
                            <div className="mt-1 text-gray-500 text-[15px] font-normal client-info-text">
                                {client_title}
                            </div>
                            <div className="mt-1 text-gray-500 text-[15px] font-normal client-info-text">
                                {business_name}
                            </div>
                            <div className="mt-1 text-gray-500 text-[15px] font-normal client-info-text">
                                {region}
                            </div>
                        </figcaption>
                    </div>
                )}
                <div
                    className={
                        !client_image.id ? 'flex w-full' : 'flex w-3/4 ml-6'
                    }
                >
                    <blockquote className="text-32 font-normal leading-[40px] text-purple sm:text-2xl client-quote">
                        <p>{quote}”</p>
                    </blockquote>
                </div>
            </figure>
        </div>
    </section>
);


};

export default ClientQuote;